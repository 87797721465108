<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="items"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          :options="pagination"
          :total="serverItemsLength"
          btn-action-item-key-id="id"
          btn-action-path="audits"
          hide-btn-action-view
          is-route-name
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template #[`item.user`]="{ item }">
            <name-with-avatar
              :name="item.user.name"
              :path="item.user.avatar"
              :subtitle="item.user.email"
              :to="{ name: 'users.update', params: { id: item.user_id } }"
            />
          </template>

          <template #[`item.auditable_type`]="{ item }">
            <v-btn
              :to="{ name: 'audits.update', params: { id: item.id } }"
              color="primary"
              text
            >
              {{ item.model }}
            </v-btn>
          </template>

          <template #[`item.event`]="{ item }">
            <v-chip
              :color="item.color"
              dark
              label
              v-text="$t(`event.${item.event}`)"
            />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <audits-form
      :key="$route.params.id"
      v-model="obItem"
      :open.sync="displayForm"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AuditsMixin from "@/modules/audits/mixins/AuditsMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import AuditsForm from "@/modules/audits/components/Form.vue";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import { isEmpty, kebabCase, last, map, replace, set } from "lodash";
import type { AuditData } from "@planetadeleste/vue-mc-audits";
import dayjs from "dayjs";
import type { UserData } from "@planetadeleste/vue-mc-shopaholic";

@Component({
  components: {
    NameWithAvatar,
    ActiveIcon,
    AuditsForm,
  },
})
export default class AuditsList extends Mixins(AuditsMixin) {
  get items() {
    return map(this.obCollection.getModelList(), (obItem: AuditData) => {
      set(obItem, "created_at", dayjs(obItem.created_at).format("L LT"));

      if (obItem.event) {
        let sColor = "blue-grey";
        switch (obItem.event) {
          case "created":
            sColor = "teal";
            break;
          case "deleted":
            sColor = "red";
            break;
          case "updated":
            sColor = "deep-purple";
            break;
        }

        set(obItem, "color", sColor);
      }

      if (obItem.auditable_type) {
        const sModelName = last(obItem.auditable_type.split("\\")) as string;
        if (sModelName) {
          const sModel = replace(kebabCase(sModelName), "-", ".");
          set(obItem, "model", this.$t(sModel));
        }
      }

      if (!obItem.user || isEmpty(obItem.user)) {
        const obUserSys: Partial<UserData> = { name: "Sistema" };
        set(obItem, "user", obUserSys);
      }

      return obItem;
    });
  }

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "user", value: "user" },
      { text: "event", value: "event" },
      { text: "model", value: "auditable_type" },
      { text: "date", value: "created_at" },
    ];
    this.setDTHeaders(arHeaders);

    this.index();
  }
}
</script>
